import styled from 'styled-components'
import { th } from '@smooth-ui/core-sc'

export const StyledContainer = styled.span`
  position: relative;
  display: block;

  &:after {
    content: 'Required';
    position: absolute;
    right: 20px;
    top: 2px;
    font-size: 14px;
    line-height: 42px;
    opacity: ${props => props.error ? 1 : 0.3};
    display: block;
    display: ${props => props.error ? 'block' : 'none'};
    color: ${props => props.error && th('primary')};
  }
`

export const StyledTextArea = styled.textarea`
  position: relative;
  border: 2px solid ${th('gray100')};
  display: block;
  width: 100%;
  border-radius: 21px;
  line-height: 42px;
  padding: 0 24px;
  border-color: ${props => props.error && th('primary')};

  &:focus {
    border-color: ${props => props.error ? th('primary') : th('gray300')};
    // commented because inputs do not work in safari otherwise 
    // user-select: none;
    outline: none;
  }
`