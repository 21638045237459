import styled from 'styled-components'
import Img from 'gatsby-image'
import { th, up, css, Col } from '@smooth-ui/core-sc'
import Plx from 'react-plx'

import { MainSection } from '../../style/globalStyleComponents'

export const HeroSection = styled(MainSection)`
  overflow: hidden;
  padding: 50px 0;
  display: flex;
  align-items: center;

  ${up('lg', css`
    height: calc(100vh - 54px);
  `)}
`

export const FullHeightImg = styled(Img)`
  height: 100%;
`

export const HeroText = styled.div`
  display: flex;
  justify-content: center;
  line-height: 40px;
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  font-size: 32px;
  text-transform: uppercase;
  color: rgba(26, 26, 26, 0.3);
  white-space: nowrap;
  padding: 20px 0;

  ${up('sm', css`
    font-size: 48px;
    line-height: 64px;
  `)}

  ${up('md', css`
    font-size: 58px;
    line-height: 72px;
  `)}

  ${up('lg', css`
    font-size: 64px;
    line-height: 94px;
  `)}
`

export const Line = styled.p`
  margin: 0;
`

export const Strong = styled.strong`
  color: ${th('primary')};
`

export const ScreenshotContainer = styled(Col)`
  transform: translateX(40vw);

  ${up('sm', css`
    transform: translateX(30vw);
  `)}

  ${up('md', css`
    transform: translateX(40vw);
  `)}

  ${up('lg', css`
    transform: translateX(120px);
  `)}

  ${up('xl', css`
    transform: translateX(160px);
  `)}
`

export const Screenshot = styled.div`
  width: 50vw;
  max-width: 220px;

  ${up('xl', css`
    max-width: 250px;
  `)}
`

export const DarkParallax = styled(Plx)`
  position: absolute;
  top: -20px;
  left: -30vw;

  ${up('sm', css`
    left: -140px;
  `)}
`