import React, { useState } from 'react'
import { Grid, Row, Col, th } from '@smooth-ui/core-sc'
import { Formik } from 'formik'

import { VerticalSpacer, MainSection, SectionScrollAnchor, ErrorMessage } from '../../style/globalStyleComponents'
import { TextInput } from '../TextInput/TextInput'
import { TextArea } from '../TextArea/TextArea'
import { Button } from '../Button/Button'

const STATUS = {
  SUCCESS: 'success',
}

const validate = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.message) {
    errors.message = 'Required'
  }

  return errors
}

const formikSubmit = (values, actions) => {
  const { setFieldValue, setFieldError, setTouched, setSubmitting, resetForm } = actions
  const formData = new FormData()
  const customResetForm = () => {
    setFieldValue('name', '')
    setFieldValue('email', '')
    setFieldValue('message', '')
    setTouched({
      name: false,
      email: false,
      message: false,
    })
    setSubmitting(false)
  }
  const setFeedback = (message) => {
    setFieldValue('feedback', message)
      setTimeout(() => setFieldValue('feedback', ''), 4000)
  }

  formData.append('name', values.name)
  formData.append('email', values.email)
  formData.append('message', values.message)

  console.log(actions)

  fetch(`${process.env.GATSBY_SPORTME}/api/public/feedback/contact_us`, {
    method: 'POST',
    body: formData,
  }).then(res => res.json())
  .then(res => {
    if (res.status === STATUS.SUCCESS) {
      customResetForm()
      setFeedback(`Thanks. We'll get back to you as soon as possible.`)
    } else {
      setFeedback(`Something went wrong.`)
    }
  }).catch(() => {
    setFeedback(`Something went wrong.`)
    setSubmitting(false)
  })
}

const initialValues = {
  name: '',
  email: '',
  message: '',
  feedback: '',
}

export const ContactUs = (props) => {
  return (
    <MainSection>
      <SectionScrollAnchor ref={props.innerRef} />
      <VerticalSpacer>
        <h1>{props.title}</h1>
        <h3>{props.subtitle}</h3>
      </VerticalSpacer>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={formikSubmit}>
        {({values, errors, touched, handleChange, handleSubmit, isSubmitting}) => (
          <form onSubmit={handleSubmit}>
            <Grid pb="70px">
              {values.feedback && (
                <Row>
                  <Col
                    xs={12} md={8}
                    omd={2}
                    textAlign="center"
                    mb="20px">
                    {}
                    <ErrorMessage>{values.feedback}</ErrorMessage>
                  </Col>
                </Row>
                )
              }
              <Row>
                <Col
                  xs={12} md={4}
                  omd={2}
                  mb="20px">
                  <TextInput
                    aria-label='input for name'
                    name="name"
                    expand
                    disabled={isSubmitting}
                    onChange={handleChange}
                    value={values.name}
                    placeholder="Name" />
                </Col>
                <Col xs={12} md={4} mb="20px">
                  <TextInput
                    aria-label='input for email'
                    name="email"
                    expand
                    disabled={isSubmitting}
                    onChange={handleChange}
                    value={values.email}
                    error={errors.email && touched.email && errors.email}
                    placeholder="Email" />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12} md={8}
                  omd={2}
                  mb="20px">
                  <TextArea
                    aria-label='input for message'
                    name="message"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    value={values.message}
                    error={errors.message && touched.message && errors.message}
                    placeholder="Message" />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12} md={8}
                  omd={2}
                  css={`text-align: center;`}>
                  <Button type="submit" disabled={isSubmitting} aria-label='send message'>Send</Button>
                </Col>
              </Row>
            </Grid>
          </form>
        )}
      </Formik>
    </MainSection>
  )
}
