import React from 'react'
import { th } from '@smooth-ui/core-sc'

import { TextInput } from '../TextInput/TextInput'

export const PaceCalculatorInput = (props) => (
  <TextInput
    {...props}
    css={`
      border-color: ${props => props.disabled ? th('white600') : th('secondary')};
      color: ${props => props.disabled ? th('white600') : th('secondary')};
      `} />
)