import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { StyledLink } from './styles'

export const GoogleButton = (props) => {
  const { light, dark } = useStaticQuery(graphql`
    {
      light: file(name:{eq:"light-android-download-icon"}) {
        publicURL
      }
      dark: file(name:{eq:"dark-android-download-icon"}) {
        publicURL
      }
    }
  `)

  return (
    <StyledLink {...props} href="https://play.google.com/store/apps/details?id=com.sportme.android&hl=en"
                aria-label='link to sport me in google play'>
      <img src={props.dark ? dark.publicURL : light.publicURL} />
    </StyledLink>
  )
}
