import styled from 'styled-components'
import { th } from '@smooth-ui/core-sc'

export const Container = styled.section`
  background: ${th('gray900')};
  padding: 30px;
`

export const Name = styled.h2`
  color: ${th('secondary')};
  margin: 0;
  font-weight: bold;
`
