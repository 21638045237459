import React from 'react'

import { StyledTextArea, StyledContainer } from './styles'

export const TextArea = (props) => {
  return (
    <StyledContainer {...props}>
      <StyledTextArea {...props} />
    </StyledContainer>
  )
}
