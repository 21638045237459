import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { StyledLink } from './styles'

export const WebButton = (props) => {
  const { light, dark } = useStaticQuery(graphql`
    {
      light: file(name:{eq:"light-web-download-icon"}) {
        publicURL
      }
      dark: file(name:{eq:"dark-web-download-icon"}) {
        publicURL
      }
    }
  `)

  return (
    <StyledLink href={`${process.env.GATSBY_SPORTME}/login`} {...props} aria-label='link to login'>
      <img src={props.dark ? dark.publicURL : light.publicURL} />
    </StyledLink>
  )
}
