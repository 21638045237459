import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Grid, Row, Col, Box, th } from '@smooth-ui/core-sc'

import { Content } from './styles'
import { VerticalSpacer, MainSection, SectionScrollAnchor } from '../../style/globalStyleComponents'

export const HowItWorks = (props) => {

  return (
    <MainSection>
      <SectionScrollAnchor ref={props.innerRef} />
      <Grid fluid>
        <Row alignItems="flex-end">
          <Col xs={12} md={7} lg={6} olg={1} order={{ md: 2 }}>
            <VerticalSpacer>
              <h1>{props.title}</h1>
              <h3>{props.subtitle}</h3>
            </VerticalSpacer>
            <Content dangerouslySetInnerHTML={{ __html: props.content }} />
          </Col>
          <Col xs={8} md={5} lg={3} order={{ md: 1 }} gutter={0}>
            <Img fluid={props.thumbnail} />
          </Col>
        </Row>
      </Grid>
    </MainSection>
  )
}

HowItWorks.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  thumbnail: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
}