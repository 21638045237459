import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Grid, Col, Row, Box, th } from '@smooth-ui/core-sc'

import { renderDate } from '../../utils/utils'
import { Name } from './styles'
import { VerticalSpacer } from '../../style/globalStyleComponents'
import { AppleButton } from '../Button/AppleButton'
import { GoogleButton } from '../Button/GoogleButton'

import { MainSection, SectionScrollAnchor } from '../../style/globalStyleComponents'

export const Reviews = (props) => {
  const { reviews, innerRef } = props
  const { star } = useStaticQuery(graphql`{
    star: file(name:{eq:"star"}) {
      publicURL
    }
  }`)
  return (
    <MainSection dark>
      <SectionScrollAnchor ref={innerRef} />
      <Grid>
        <Row>
          <Col>
            <VerticalSpacer>
              <h1 css={`color: ${th('primary')}`}>{props.title}</h1>
              <h3 css={`color: ${th('white600')}`}>{props.subtitle}</h3>
            </VerticalSpacer>
          </Col>
        </Row>
        <Row>
          {reviews.map(({ node }, idx) => {
            const stars = Array(parseInt(node.data.stars, 10)).fill(null)
            return (
              <Col
                key={idx}
                textAlign="center"
                xs={12} md={4}
                mb={{ xs: '50px', md: 0 }}>
                <Name>{node.data.title.text}</Name>
                <div css={`color: ${th('white600')}`}>{node.data.name.text}</div>
                {stars.map((k, i) => <img src={star.publicURL} key={i}/>)}
                <Box
                  color="#fff"
                  textAlign="justify"
                  mt="25px">"{node.data.content}"</Box>
              </Col>
            )
          })}
        </Row>
        <Row justifyContent="center">
          <Col>
            <VerticalSpacer>
              <h3 css={`color: ${th('white600')}`}>Read all the reviews on the AppStore</h3>
              <Row mt="20px" gutter={0}>
                <Col textAlign={{xs:'center', md: 'right'}}>
                  <AppleButton dark />
                </Col>
                <Col textAlign={{xs:'center', md: 'left'}}>
                  <GoogleButton dark />
                </Col>
              </Row>
            </VerticalSpacer>
          </Col>
        </Row>
      </Grid>
    </MainSection>
  )
}

Reviews.propTypes = {
  innerRef: PropTypes.any,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}