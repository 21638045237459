import React, { useRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import Observer from "@researchgate/react-intersection-observer"
import { Helmet } from 'react-helmet'

import { MainLayout } from '../layouts/MainLayout'
import { Header } from "../components/Header/Header"
import { Hero } from '../components/Hero/Hero'
import { BlogPreview } from '../components/BlogPreview/BlogPreview'
import { Reviews } from '../components/Reviews/Reviews'
import { HowItWorks } from '../components/HowItWorks/HowItWorks'
import { PaceCalculator } from '../components/PaceCalculator/PaceCalculator'
import { ContactUs } from '../components/ContactUs/ContactUs'
import { checkPropTypes } from "prop-types";
import 'intersection-observer'

async function loadPolyfills() {
    if (typeof window.IntersectionObserver === 'undefined') {
        await import('intersection-observer')
    }
}


const IndexPage = ({ data, location }) => {
  const [selectedSection, setSelectedSection] = useState(0)
  const getAppEl = useRef(null)
  const howItWorksEl = useRef(null)
  const paceCalculatorEl = useRef(null)
  const blogEl = useRef(null)
  const reviewsEl = useRef(null)
  const contactUsEl = useRef(null)
  const onlyOnFirstRender = []

  const { prodBlogposts, blogPosts, prodReviews, reviews, prismicStaticContent} = data
  const results = process.env.GATSBY_ACTIVE_ENV === 'production' ? prodBlogposts : blogPosts;
  const reviewResults =  process.env.GATSBY_ACTIVE_ENV === 'production' ? prodReviews : reviews;
  useEffect(() => {
    const hasSelectedSection = location.state && typeof location.state.selectedSection !== 'undefined'
    loadPolyfills()
    if (hasSelectedSection) {

      //Hack for scrolling to selected section
      // TODO - try to remove the setTimeout call
      setTimeout(() => scrollTo(location.state.selectedSection, 'auto'), 1)
    }
  }, onlyOnFirstRender)

  const scrollTo = (index, behavior = 'smooth') => {
    setSelectedSection(index)
    sections[index].current.scrollIntoView({
      behavior,
    })
  }
  const setVisible = index => ({ isIntersecting }) =>
    isIntersecting && setSelectedSection(index)

  const sections = [
    getAppEl,
    howItWorksEl,
    paceCalculatorEl,
    blogEl,
    reviewsEl,
    contactUsEl,
  ]

  return (
    <MainLayout pathname={location.pathname}>
      <Helmet title='Marathon Sports Training App'
        meta={[
          { property: 'og:title', content: 'Marathon Sports Training App | SportMe', },
          { property: 'og:url', content: `${location.href}` },
          {
            property: 'og:description',
            content: `SportMe is a Marathon Sports training app. It offers custom training plans for the marathon, half-marathon, 5K, and 10K by experienced running coaches and AI.!`
          },
          {
            property: 'keywords', content: 'Marathon Sports'
          }


       ]} />
      <Header
        onSelect={scrollTo}
        selected={selectedSection}
        pathname={location.pathname} />

      <Observer onChange={setVisible(0)} rootMargin="0% 0% -25%">
        <div>
          <Hero innerRef={getAppEl} />
        </div>
      </Observer>

      <Observer onChange={setVisible(1)} rootMargin="0% 0% -25%">
        <div>
          <HowItWorks
            innerRef={howItWorksEl}
            title={prismicStaticContent.data.title_how_it_works.text}
            subtitle={prismicStaticContent.data.subtitle_how_it_works.text}
            thumbnail={prismicStaticContent.data.thumbnail_how_it_works.localFile.childImageSharp.fluid}
            content={prismicStaticContent.data.content_how_it_works.html} />
        </div>
      </Observer>

      <Observer onChange={setVisible(2)} rootMargin="0% 0% -25%">
        <div>
          <PaceCalculator
            innerRef={paceCalculatorEl}
            title={prismicStaticContent.data.title_pace_calculator.text}
            subtitle={prismicStaticContent.data.subtitle_pace_calculator.text}
            content={prismicStaticContent.data.content_pace_calculator.html} />
        </div>
      </Observer>

      <Observer onChange={setVisible(3)} rootMargin="0% 0% -25%">
        <div>
          <BlogPreview
            blogposts={results.edges}
            innerRef={blogEl}
            title1={prismicStaticContent.data.title_latest_articles.text}
            subtitle1={prismicStaticContent.data.subtitle_latest_articles.text} title2={prismicStaticContent.data.title_want_more.text}
            subtitle2={prismicStaticContent.data.subtitle_want_more.text} />
        </div>
      </Observer>

      <Observer onChange={setVisible(4)} rootMargin="0% 0% -25%">
        <div>
          <Reviews
            reviews={reviewResults.edges}
            innerRef={reviewsEl}
            title={prismicStaticContent.data.title_our_users.text}
            subtitle={prismicStaticContent.data.subtitle_our_users.text} />
        </div>
      </Observer>

      <Observer onChange={setVisible(5)} rootMargin="0% 0% -25%">
        <div>
          <ContactUs
            innerRef={contactUsEl}
            title={prismicStaticContent.data.title_contact_us.text}
            subtitle={prismicStaticContent.data.subtitle_contact_us.text} />
        </div>
      </Observer>
    </MainLayout>
  )
}

export const query = graphql`
query {
  prodBlogposts: allPrismicBlogpost(filter:{data:{ show_in_production: {eq:"Yes"}}
  }, limit: 3, sort: {
    fields: first_publication_date,
    order: DESC
  }) {
    edges {
      node {
        uid
        last_publication_date
        data {
          content {
            text
          }
          show_in_production
          title {
            text
          }
          thumbnail {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 420) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }

  blogPosts:  allPrismicBlogpost(limit: 3, sort: {
    fields: first_publication_date,
    order: DESC
  }) {
    edges {
      node {
        uid
        last_publication_date
        data {
          content {
            text
          }
          show_in_production
          title {
            text
          }
          thumbnail {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 420) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }

  prodReviews: allPrismicReview(filter:{data:{ show_in_production: {eq:"Yes"}}}, limit: 3, sort: {fields: last_publication_date, order: DESC}) {
    edges {
      node {
        id
        last_publication_date
        data {
          name {
            text
          }
          title {
            text
          }
          content
          stars
        }
      }
    }
  }

  reviews:  allPrismicReview(limit: 3, sort: {fields: last_publication_date, order: DESC}) {
    edges {
      node {
        id
        last_publication_date
        data {
          name {
            text
          }
          title {
            text
          }
          content
          stars
        }
      }
    }
  }

  prismicStaticContent {
    data {
      content_how_it_works {
        html
      }
      content_pace_calculator {
        html
      }
      subtitle_contact_us {
        text
      }
      subtitle_how_it_works {
        text
      }
      thumbnail_how_it_works {
        localFile {
          childImageSharp {
            fluid(maxWidth: 400, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      subtitle_latest_articles {
        text
      }
      subtitle_our_users {
        text
      }
      subtitle_pace_calculator {
        text
      }
      subtitle_want_more {
        text
      }
      title_contact_us {
        text
      }
      title_how_it_works {
        text
      }
      title_latest_articles {
        text
      }
      title_our_users {
        text
      }
      title_pace_calculator {
        text
      }
      title_want_more {
        text
      }
    }
  }
}
`

export default IndexPage
