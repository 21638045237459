import styled from 'styled-components'
import { th } from '@smooth-ui/core-sc'

export const StyledSelect = styled.select`
  padding: 5px;
  border: 2px solid ${props => props.disabled ? th('white600') : th('secondary')};
  height: 42px;
  border-radius: 24px;
  background: transparent;
  color: ${props => props.disabled ? th('white600') : th('secondary')};
  display: inline-block;
  width: ${props => props.expand && '100%'};
`