import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { StyledLink } from './styles'

export const AppleButton = (props) => {
  const { light, dark } = useStaticQuery(graphql`
    {
      light: file(name:{eq:"light-appstore-download-icon"}) {
        publicURL
      }
      dark: file(name:{eq:"dark-appstore-download-icon"}) {
        publicURL
      }
    }
  `)

  return (
    <StyledLink {...props} href="https://apps.apple.com/us/app/sportme-marathon-run-trainer/id588594735"
                aria-label='link to sport me in app store'>
      <img src={props.dark ? dark.publicURL : light.publicURL} />
    </StyledLink>
  )
}
