import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Grid, Row, Col, Breakpoint } from '@smooth-ui/core-sc'
import Plx from 'react-plx'

import { AbsoluteContainer, SectionScrollAnchor } from '../../style/globalStyleComponents'
import {
  FullHeightImg,
  HeroText,
  Line,
  Strong,
  ScreenshotContainer,
  Screenshot,
  DarkParallax,
  HeroSection,
} from './styles'
import { AppleButton } from '../Button/AppleButton'
import { GoogleButton } from '../Button/GoogleButton'
import { WebButton } from '../Button/WebButton'

const parallax1 = [
  {
    start: 0,
    end: 600,
    properties: [
      {
        startValue: 1,
        endValue: 100,
        property: 'translateY',
      },
    ],
  },
]
const parallax2 = [
  {
    start: 0,
    end: 400,
    properties: [
      {
        startValue: 1,
        endValue: -100,
        property: 'translateY',
      },
    ],
  },
]

export const Hero = props => {
  const { image, screenshot1, screenshot2 } = useStaticQuery(graphql`
    {
      image: file(name: { eq: "map-hero-image" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screenshot1: file(name: { eq: "app-screenshot-1" }) {
        childImageSharp {
          fluid(maxWidth: 230, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      screenshot2: file(name: { eq: "app-screenshot-2" }) {
        childImageSharp {
          fluid(maxWidth: 230, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <HeroSection>
      <SectionScrollAnchor ref={props.innerRef} />
      <Grid css={`position: relative; z-index: 2`}>
        <Row>
          <Col
            xs={12} lg={8}
            zIndex={3}
            order={{ lg: 2 }}>
            <Plx parallaxData={parallax1}>
              <HeroText>
                <div css={{ textAlign: 'right', paddingRight: '20px' }}>
                  <Line>&nbsp;</Line>
                  <Line>With a</Line>
                  <Line>That will</Line>
                  <Line>You</Line>
                </div>
                <div>
                  <Line>
                    <Strong>Start</Strong> training
                  </Line>
                  <Line>
                    <Strong>plan</Strong>
                  </Line>
                  <Line>
                    <Strong>get</Strong>
                  </Line>
                  <Line>
                    <Strong>in shape</Strong>.
                  </Line>
                </div>
              </HeroText>

              <Breakpoint up="lg">
                <AppleButton css={{ marginRight: '10px' }} />
                <GoogleButton css={{ marginRight: '10px' }} />
                <WebButton />
              </Breakpoint>
            </Plx>
          </Col>
          <ScreenshotContainer xs={12} lg={4}>
            <Plx parallaxData={parallax2}>
              <Screenshot>
                <Img fluid={screenshot2.childImageSharp.fluid} />
              </Screenshot>
            </Plx>
            <DarkParallax>
              <Screenshot>
                <Img fluid={screenshot1.childImageSharp.fluid} />
              </Screenshot>
            </DarkParallax>
          </ScreenshotContainer>
        </Row>
        <Breakpoint down="lg">
          <Row>
            <Col>
              <AppleButton css={{ marginRight: '10px' }} />
              <GoogleButton css={{ marginRight: '10px' }} />
              <WebButton />
            </Col>
          </Row>
        </Breakpoint>
      </Grid>
      <AbsoluteContainer>
        <FullHeightImg fluid={image.childImageSharp.fluid} />
      </AbsoluteContainer>
    </HeroSection>
  )
}
