import styled from 'styled-components'
import { th, up, css, Row } from '@smooth-ui/core-sc'

export const Label = styled.label`
  font-size: ${th('h3FontSize')};
  font-weight: bold;
  display: block;
`

export const SectionTitle = styled.h3`
  color: ${th('secondary')};
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
`

export const ResultLabel = styled.p`
  margin: 0;
  white-space: nowrap;
  text-align: right;
  padding-right: 15px;
`

export const Result = styled.p`
  margin: 0;
  white-space: nowrap;
  color: #fff;
  font-weight: bold;
`

export const ResultsContainer = styled(Row)`
  margin-bottom: 30px;
  padding-bottom: 30px;

  ${up('lg', css`
    background: ${th('gray500')};
    border-radius: 30px;
  `)}
`